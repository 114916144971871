/** @jsx jsx */
import { Styled, jsx } from "theme-ui"
import Image from "gatsby-image"
import PageLayout from "./page-layout"
import DottedCard from "../components/dotted-card"
import Animate from "../components/animations"
import { graphql } from "gatsby"

export default function AboutUsLayout({ data }) {
  const { frontmatter, html } = data.markdownRemark
  const isAr = frontmatter.lang === "ar"

  return (
    <PageLayout
      opacity={0.3}
      backgroundColor="#000"
      breadcrumbsPaths={[{ title: isAr ? "من نحن" : "About us", path: "" }]}
      mainImage={frontmatter?.bg?.childImageSharp?.fluid || {}}
      title={frontmatter?.title}
    >
      <div
        sx={{
          my: 80,
          px: 3,
          display: "flex",
          flexWrap: ["wrap", "nowrap", "nowrap"],
          justifyContent: "center",
        }}
      >
        <div sx={{ width: ["100%", null, 600] }}>
          <Animate animationIn="fadeInUp">
            <Image fluid={frontmatter?.img?.childImageSharp?.fluid || {}} />
          </Animate>
        </div>
        <div sx={{ flex: "0 1 719px" }}>
          <Animate animationInDelay={200} animationIn="fadeInUp">
            <DottedCard>
              <small sx={{ color: "primary" }}>{frontmatter?.hint}</small>
              <Styled.h2
                sx={{
                  "&::after": {
                    content: '""',
                    width: 52,
                    height: "2px",
                    my: 20,
                    backgroundColor: "muted",
                    display: "block",
                  },
                }}
              >
                {frontmatter?.subTitle}
              </Styled.h2>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </DottedCard>
          </Animate>
        </div>
      </div>
    </PageLayout>
  )
}

export const aboutUsQuery = graphql`
  query($lang: String!) {
    markdownRemark(
      frontmatter: { lang: { eq: $lang } }
      fileAbsolutePath: { regex: "/pages/about/" }
    ) {
      frontmatter {
        title
        hint
        lang
        subTitle
        img {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        bg {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      html
    }
  }
`
