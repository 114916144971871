/** @jsx jsx */
import { jsx, Card } from "theme-ui"

export default function DottedCard({ children }) {
  return (
    <Card
      sx={{
        position: "relative",
        mt: 80,
        marginInlineEnd: 0,
        "&::after": {
          content: '""',
          background: `url(${require("../images/bg-3.png")})`,
          display: "block",
          width: "100%",
          height: "100%",
          position: "absolute",
          marginInlineStart: 72,
          bottom: -76,
          zIndex: 0,
        },
      }}
    >
      <div
        sx={{
          zIndex: 1,
          position: "relative",
          backgroundColor: "background",
          p: 50,
        }}
      >
        {children}
      </div>
    </Card>
  )
}
